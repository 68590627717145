/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
          /**
           * NAME: Bootstrap 3 Triple Nested Sub-Menus
           * This script will active Triple level multi drop-down menus in Bootstrap 3.*
           */
          if ($(window).width() > 480 || $(window).height() < 480) {
              $('.dropdown').on('mouseenter mouseleave click tap', function () {
                  //alert(this);
                  $(this).toggleClass("open");
              });
          }

          //$('#pump-carousel').bcSwipe({ threshold: 50 });

          $('a.dropdown-toggle [data-toggle=dropdown]').on('mouseenter mouseleave click tap', function(event) {
              console.log('dd');

              // Avoid following the href location when clicking
              //event.preventDefault();
              // Avoid having the menu to close when clicking
              event.stopPropagation();
              // Re-add .open to parent sub-menu item
              $(this).parent().addClass('open');
              $(this).parent().siblings().removeClass('open');
              $(this).parent().find("ul").parent().find("li.dropdown").addClass('open');

          });
          $('#pump-carousel .carousel-inner .item a').click(function (e) {
              var currentIndex = $('#pump-carousel .carousel-inner div.active').index();
              console.log(currentIndex);
              $("#carousel-example-generic-modal").carousel(currentIndex);
          });

          $('#pump-modal').on('show.bs.modal', function () {
              // do something…
              $('#pump-carousel').carousel('pause');

          })
          $('#pump-modal').on('shown.bs.modal', function () {
              // do something…

          })
          $('#pump-modal').on('hide.bs.modal', function () {
              //$("#carousel-example-generic").carousel(currentIndex);
             // $('#pump-carousel').carousel('cycle');
              var currentIndex = $('#carousel-example-generic-modal .carousel-inner div.active').index();
              $("#pump-carousel").carousel(currentIndex);

              // do something…
          })
          $('#myModal').on('hidden.bs.modal', function () {
              // do something…

          });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
          $('.home .carousel').carousel({
              pause: "false"
          });
          $('.home .carousel').on('slide.bs.carousel', function(ev) {
             var dir = ev.direction === 'right' ? 'prev' : 'next';
              $('.home .carousel').not('.sliding').addClass('sliding').carousel(dir);
          });
          $('.home .carousel').on('slid.bs.carousel', function(ev) {
             // $('.home .carousel').removeClass('sliding');
          });
          $('.home .pos-rel .carousel .carousel-indicators').on('click', function() {
             // alert('clicked');
              $('.home .rtcol-carousel .carousel').carousel(parseInt(this.getAttribute('data-to')));
          });



          // JavaScript to be fired on the home page
      },

      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
